@import "~react-vis/dist/style";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input{
  background-color: rgb(255, 255, 255);
}


.modal-body, .modal-content{
  background-color: #fbfafa6b;
  color: rgb(0, 0, 0);
  border-radius: 16px;
  padding: 16px;
  padding-bottom: 30px;
}
.LoginMenuTitleBottom{
  margin-left: auto;
  margin-right: auto;
}
.ButtonLogin{
  float: right;
  background-color: #202020;
  border-color: black;
}
.ButtonLogin:hover{
  background-color: #575353;
  border-color: black;
}
.swal-modal{
  background-color: #202020;
  color: white;
}
.swal-modal .swal-title,
.swal-modal .swal-text{
  color: white;
}

#offcanvasNavbar{
  background-color: #202020;
  fill: white;
}
#canvas-button{
  color: white;
  fill: white;
  filter: invert(100%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(320%) contrast(2000%);
}
.navbar{
  margin-top: 6px;
  margin-right: 12px;
  float: right;
}
#CanvasAnimationMenu{
  color: rgb(192, 192, 192);
  font-family: "Roboto","Arial",sans-serif;
  font-size: 1.2rem;
  line-height: 2.2rem;
  font-weight: 500;
  overflow: hidden;
  display: block;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}
#CanvasAnimationMenu:hover{
  color: rgb(255, 255, 255);
  font-size: 1.3rem;
}
.navBarTitle{
  color: rgb(255, 255, 255);
  font-family: "Roboto","Arial",sans-serif;
  font-size: 2rem;
  line-height: 2.2rem;
  font-weight: 500;
  overflow: hidden;
  display: block;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}
.offcanvas-header .btn-close{
  filter: invert(100%) sepia(0%) saturate(100%) hue-rotate(0deg) brightness(320%) contrast(2000%);
}

.main-menu-bar{
  background-color: #202020;
  height: 70px;
}
.main-menu-bar .row{
  --bs-gutter-x: 1;
}
.main-logo{
  font-family: "Roboto","Arial",sans-serif;
  font-size: 1.2rem;
  line-height: 2.2rem;
  font-weight: 500;
  overflow: hidden;
  display: block;
  -webkit-line-clamp: 1;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  margin: 10px;
}
.no-a-href-hover{
  color: initial;
  text-decoration: none;
}
.title-szkolenia-main{
  color: white;
}
.title-szkolenia-main:hover{
  color: rgb(211, 211, 211);
  text-decoration: none;
}



@media screen and (min-width: 576px) {
  .main-menu-bar{
    height: 70px;
  }
}
@media screen and (max-width: 575px) {
  .main-menu-bar{
    height: 70px;
  }
  .navbar{
    position: absolute;
    top: 3px;
    right: 10px;
  }
}
.container-main{
  float: left;
  padding: 40px;
}
.container-main .row{
  --bs-gutter-x: 1;
}
.row-main{
  float: left;
}
.pagination-main{
  margin: 20px;
}
.pagination-btn{
  background-color: #f9f9f9;
  color: black;
  border-color: #000000;
  border-radius: 12px;
}
.pagination-btn:hover{
  background-color: rgb(22, 22, 22);
  color: rgb(255, 255, 255);
  border-color: #b9b7b7;
  border-radius: 12px;
}
.pagination-btn:active{
  background-color: aliceblue;
  color: black;
  border-color: #292929;
}
.bar-main-sales{
  margin-left: 40px;
}
.rv-xy-plot__axis__tick text{
  text-overflow:initial !important;
  word-wrap:normal  !important;
  white-space: initial  !important;
  overflow: initial  !important;
  z-index: 999;
}
.rv-xy-plot__inner g{
  overflow: initial  !important;
}
.rv-xy-plot__inner{
  text-overflow:initial !important;
  word-wrap:normal  !important;
  white-space: initial  !important;
  overflow: initial  !important;
  z-index: 999;
  margin-left: 20px;
}
.rv-xy-plot__axis__tick__text{
  font-size: 0.55em;
}
.rv-xy-plot__axis--vertical{
  font-size: 1.4em;
}
.sales-value{
  margin-left: 70px;
  margin-top: 36px;
}
.sales-value-title{
  margin-left: 70px
}
#monthCRMTotal{
  height: 35px;
}
.react-datepicker-wrapper{
  color: black;
  background-color: #202020;
}
.month-year-test{
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
.container-datepicker{
  align-items: center;
  width: 500px;
  text-align: center;
  margin-top: 15vh;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  border-radius: 16px;
  border: 1px solid black;
  background-color: #fcfcfc;
}
.btn-main-datepicker{
  margin-top: 20px
}
#noCountries{
  background-color: rgba(255, 50, 0, 0.3);
  border-radius: 8px;
}
#yesCountries{
  background-color: rgba(0, 255, 0, 0.3);
  border-radius: 8px;
}
.rv-xy-plot__inner path{
  stroke: red;
}
#valueoFsales-year{
  background-color: #202020;
  border-radius: 16px;
  border: 1px solid black;
  text-align: center;
  color: white;
}
#valueoFsales-year th{
  color: white;
}
#resize-body{
  background-color: #2e2e2e;
  margin: 14px;
  border: 1px black solid;
  border-radius: 16px;
}
.text-date-picker{
  font-size: 13px;
  padding: 5px
}
.custom-table {
  min-width: 1000px; 
  background-color: #f9f9f9;
  border-radius: 16px;
  padding: 6px;
}
.custom-table thead tr, .custom-table thead th {
  padding-bottom: 10px;
  border-top: none;
  border-bottom: none !important;
  color: rgb(0, 0, 0);
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: .2rem; }
.custom-table tbody th, .custom-table tbody td {
  color: rgb(0, 0, 0);
  font-weight: 400;
  padding-bottom: 8px;
  padding-top: 7px;
  font-weight: 300;
  border: none;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; 
}
.custom-table tbody th small, .custom-table tbody td small {
  color: #fff;
  font-weight: 300; 
}
.custom-table tbody th a, .custom-table tbody td a {
  color: #fff; 
}
.custom-table tbody th .more, .custom-table tbody td .more {
  color: #fff;
  font-size: 11px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: .2rem; 
}
.custom-table tbody tr {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; 
}
.custom-table tbody tr:hover td, .custom-table tbody tr:focus td {
  color: rgb(255, 0, 0); 
}
.custom-table tbody tr:hover td a, .custom-table tbody tr:focus td a {
  color: #fdd114; 
}
.custom-table tbody tr:hover td .more, .custom-table tbody tr:focus td .more {
  color: #fdd114; 
}
.custom-table .td-box-wrap {
  padding: 0; 
}
.custom-table .box {
  background: rgb(255, 0, 0);
  border-radius: 4px;
  margin-top: 15px;
  margin-bottom: 15px; 
}
.custom-table .box td, .custom-table .box th {
  border: none !important; 
}
.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: #000000;
}

.custom-table2 {
  margin-left: 30px;
  min-width: 60px; 
  background-color: #f9f9f9;
  border-radius: 16px;
  padding: 6px;
  margin-right: 30px;
}
.right-title-main{
  margin-left: 30px;
}

.CRM-client-table{
  min-width: 150px;
  max-width: 150px;
}
.CRM-table{
  width: 75%;
}
.btn-crm{
  width: 345px;
  padding: 10px;
  position: fixed;
  z-index: 10000;
  bottom: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
}
/* .CRM-client-table{
  position: absolute;
  text-overflow: hidden;
  word-wrap: unset;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: scroll;
} */
.CRM-table{
  position: absolute;
  left: 250px;
}
/* .CRM-client-table tbody th, 
.CRM-client-table tbody td{
  overflow: hidden;
  overflow-x: scroll;
  width: 200px;
  max-width: 208px;
}
.CRM-table tbody th,
.CRM-table tbody td{
  overflow: hidden;
  overflow-x: scroll;
  min-width: 140px;
}
.CRM-table{
  overflow: hidden;
  overflow-x: scroll;
}
.CRM-sum-table tbody th, 
.CRM-sum-table tbody td{
  overflow: hidden;
  overflow-x: scroll;
  width: 200px;
  max-width: 208px;
}
.CRM-sum-table{
  min-width: 100px;
  width: 200px;
  max-width: 208px;
  position: absolute;
  left: calc(75% + 252px);
} */





.CRM-client-table-new .table-responsive{
  width: 80vw;
  margin-left: 15vw;
  overflow-x: scroll;
  overflow-y: visible;
  padding: 0;
 
}
.CRM-client-table-new .CRM-client-table .headcol{
  position: absolute;
  width: 5em;
  left: 0;
  top: auto;
  border-top-width: 1px;
  border-right: 1px solid grey !important;
  background-color: #ffffff;
  margin-top: -1px;
  z-index:333;
}
.CRM-client-table-new .CRM-client-table {
  overflow-x: scroll;
  overflow-y: visible;
}
.CRM-client-table-new .CRM-client-table thead .headcol{
  padding-bottom: 10px;
  border-top: none;
  border-bottom: 2px solid black !important;
  border-right: 1px solid grey !important;
  color: rgb(0, 0, 0);
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: .2rem; 
  z-index:433;
}
.CRM-client-table-new .CRM-client-table tbody .headcol{
  height: 40px;
  margin-top: 0.001rem;
}
.CRM-client-table-new .CRM-client-table .long{
  z-index: 111 !important;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  position: relative;
}
.CRM-client-table-new thead{
  overflow-x: scroll;
}
.CRM-client-table-new .CRM-client-table td,
.CRM-client-table-new .CRM-client-table th{
  white-space: nowrap;
  border-top-width: 0px;
}
.CRM-client-table-new .CRM-client-table{
  min-width: 1000px;
}
.CRM-client-table-new .table{
  margin-bottom: 0rem;
}
.CRM-client-table-new .headcol{
  min-width: 15vw;
  margin-left: 40px;
  overflow: hidden;
}
.countries-to-countries{
  margin-left: -32px;
  margin-top: 30px;
}
.product-table{
  width: 80vw;
  overflow-x: scroll;
  overflow-y: visible;
  padding: 0;
}
.product-table-new .table-responsive{
  width: 80vw;
  overflow-x: scroll;
  overflow-y: visible;
}
#year-table-center{
  text-align: center;
}